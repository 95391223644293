import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
import router from '../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dollar: null,
    token: null,
    expiresAt: null,
    role: null,
    userName: '',
    userConfirmationId: ''
  },
  getters: {
    getDollarValue: state => {
      return state.dollar
    },
    getUserName: state => {
      return state.userName
    },
    getConfirmationId: state => {
      return state.userConfirmationId
    },
    getUserRole: state => {
      return state.role
    }
  },
  mutations: {
    setDollarValue: (state, payload) => {
      state.dollar = payload
    },
    userLogOut: (state, payload) => {
      state.token = null
      state.expiresAt = null
      state.role = null
      localStorage.removeItem('token')
      localStorage.removeItem('expiresAt')
      localStorage.removeItem('userName')
    },
    setTokenAuth: (state, payload) => {
      state.token = payload.token
      state.role = payload.role
      state.expiresAt = payload.expiresAt
      state.userName = payload.userName
      state.userConfirmationId = payload.userConfirmationId
      localStorage.setItem('token', payload.token)
      localStorage.setItem('expiresAt', payload.expiresAt)
      localStorage.setItem('userName', payload.userName)
      localStorage.setItem('userConfirmationId', payload.userConfirmationId)
      if(!payload.isInitialAuth) return
      if(payload.role == 'user')
        router.push('/shop')
      else if(payload.role == 'admin')
        router.push('/admin/dashboard')
      else 
        router.push('/localshop/menu')
    }
  },
  actions: {
    postDollarValue: async ({commit}, payload) => {
      await axios.post('/dollar', {newValue: payload})
      commit('setDollarValue', payload)
    },
    httpGetDollarValue: async ({commit}) => {
      const resp = await axios.get('/dollar')
      commit('setDollarValue', resp.data)
    },
    userLogIn: async ({commit}, payload) => {
      const resp = await axios.get(`/user/role?token=${payload.token}`)
      if(!resp.data) return
      commit('setTokenAuth', {
        token: payload.token, 
        role: resp.data, 
        expiresAt: payload.expiresAt, 
        isInitialAuth: payload.isInitialAuth, 
        userName: payload.userName,
        userConfirmationId: payload.userConfirmationId,
      })
    }
  }
})
