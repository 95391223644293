<template>
    <nav>
        <ul>
            <router-link 
              v-if="$store.getters.getUserRole == 'admin'"
              tag="button" 
              class="btn btn-warning mt-3 p-2 mx-3 back-button" 
              to="/admin/dashboard">Admin
            </router-link>
            <li>
              <router-link to="/shop">
                  <img id="navImg" src="../../../assets/itcentro-logos/itcentro-logo.png">
              </router-link>
            </li>
            <!-- Collapse button -->
            <li class="d-block d-md-none p-1">
              <button @click="showSideBar = true" class="mt-3 btn btn-success p-1">☰</button>
            </li>
            <transition name="slide">
              <itc-mobile-menu v-if="showSideBar" @close="showSideBar = false" :categories="categories" :search="submitSearchByName"></itc-mobile-menu>
            </transition>
            <li class="nav d-none d-md-block item mx-lg-5 p-3">
              <form class="form-inline my-2 my-lg-0" @submit.prevent="submitSearchByName(nameSearcher);nameSearcher=''">
                <input v-model="nameSearcher" class="form-control mx-2" placeholder="Que estas buscando?">
                <button class="btn btn-success p-1" type="submit">Buscar</button>
              </form>
            </li>
            <!--- //TODO: Pasar esto a otro componente --->
            <li class="submenu d-none d-md-block">
              <a href="#" class="pt-4" @click="showProductsMenu = !showProductsMenu">Productos</a>
              <ul class="megamenu" v-if="showProductsMenu">
                  <ul v-for="(category, i) in categories" :key="i" class="col-12 col-sm-6 col-md-4 col-xl-2 p-1">
                      <h4 class="menu-item">
                        {{category.showName}}
                      </h4>
                      <li v-for="(subCategory, index) in category.subCategories" :key="`${i}/${index}`">
                          <router-link
                            @click.native="showProductsMenu = false"
                            class="submenu-item"
                            :to="{path: `/shop/products/${category.urlName}/${subCategory.urlName}`}"
                            > {{subCategory.showName}}
                          </router-link>
                      </li>
                  </ul>
              </ul>
            </li>
        </ul>
    </nav>
</template>

<script>
import MobileMenu from './MobileMenu'

import axios from 'axios'

export default {
  data(){
      return {
          categories: [],
          showProductsMenu: false,
          nameSearcher: '',
          showSideBar: false
      }
  },
  methods: {
    submitSearchByName(searcher){
      this.showSideBar = false
      if(searcher == '') return;
      this.$router.push({path: "/shop/products/search", query:{searcher}});
    },
  },
  async mounted(){
      const resp = await axios.get('/category/complete')
      this.categories = resp.data
  },
  components: {
    'itc-mobile-menu': MobileMenu
  }
}
</script>

<style scoped>
.back-button{
  position: fixed;
  left: 0;
  top: 0
}
.slide-enter-active,
.slide-leave-active
{
    transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
    transform: translateX(-100%);
    transition: all 150ms ease-in 0s
}
body {
  background: #32CD32;
}
img#navImg {
    width: 150px;
    height: 40px;
}
.submenu-item {
    font-size: small;
}

.menu-item {
    font-size: medium
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
}

nav {
  position: relative;
  width: 100%;
  background:#D3D3D3;
  font-size: 16px;
  /*box-shadow: 0 0 10px rgba(0,0,0,1);*/
}

/* first stage */

nav > ul {
  position: relative;
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 0 auto;
  padding: 0;
}

nav a {
  display: block;
  color: #000;
  text-decoration: none;
}

nav ul li {
  color: #000;
  list-style: none;
  transition: 0.5s;
}

nav > ul > li > a {
  padding: 15px 25px;
}

nav ul > li.submenu > a:after {
  position: relative;
  float: right;
  content: '';
  margin-left: 10px;
  margin-top: 8px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
  border-bottom: 5px solid transparent;
}

nav ul > li.submenu:hover > a:after {
  margin-top: 2px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid #000;
}

nav > ul > li:hover {
  background: #32CD32;
}

/* second stage (the mega-menu) */

nav ul.megamenu {
  position: absolute;
  display: flex;
  flex-wrap: wrap; 
  width: 100%;
  top: 100%;
  left: 0;
  padding: 40px 40px 0 40px;
  background: #fff;
  text-align: left;
  border-top: 5px solid #32CD32;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

/* third stage (child-menus in the mega-menu) */

nav ul.megamenu ul {
  width: 25%;
  margin-bottom: 20px;
  color: #000;
  box-shadow: none;
}

nav ul.megamenu h4 {
  margin-bottom: 15px;
  text-transform: uppercase;
  border-bottom: 1px solid #32CD32
}

nav ul.megamenu ul li {
  display: block;
}

nav ul.megamenu ul li a {
  margin-top: 5px;
  transition: 0.5s;
  color: #000;
}

nav ul.megamenu ul li a:hover {
  color: #32CD32;
}
</style>
