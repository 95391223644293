<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import axios from 'axios'

export default{
  async created(){
    //TODO: Arreglar todo esto
    const token = localStorage.getItem('token')
    if(!token) return
    const expiresAt = localStorage.getItem('expiresAt')
    const userName = localStorage.getItem('userName')
    const userConfirmationId = localStorage.getItem('userConfirmationId')
    const now = (new Date()).toJSON()
    if(now >= expiresAt) return this.$router.replace('/user/signin')
    this.$store.dispatch('userLogIn', {token, expiresAt, isInitialAuth: false, userName, userConfirmationId})
  }
}
</script>

<style lang="scss">
  .custom-shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)
  }
  a {
    text-decoration: none !important
  }
  .hoverable:hover{
    background-color: lightblue 
  }
  .custom-margin{
    margin-top: 32px
  }
  .set-pointer {
    cursor:pointer
  }
.align-div{
  margin: 0 auto
}
</style>
