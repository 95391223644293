<template>
    <div class="shop-home">
        <itc-navigation></itc-navigation>
          <router-view/>
        <itc-footer></itc-footer>
    </div>
</template>

<script>
import Navigation from '../components/shared/shop/Navigation'
import Footer from '../components/shared/shop/Footer'

export default {
  components: {
    'itc-navigation': Navigation,
    'itc-footer': Footer
  }
}
</script>