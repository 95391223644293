<template>
    <div id="mySidebar" class="custom-shadow sidebar">
        <a @click="$emit('close')" class="closebtn">&times;</a>
        <form class="form-inline px-2" @submit.prevent="search(nameSearcher);nameSearcher=''">
            <div class="row">
                <div class="input-group px-4">
                    <input ref="search" type="text" placeholder="Buscar" class="form-control" v-model="nameSearcher">
                    <div class="input-group-append">
                        <button class="btn btn-success" type="submit"><i class="fas fa-search"></i></button>
                    </div>
                </div>
            </div>
        </form>
        <hr class="mx-4">
        <div>
             <ul v-for="(category, i) in categories" :key="i" class="col-12 menu-categories">
                <h4 class="menu-item mt-4">
                    {{category.showName}}
                    <hr>
                </h4>
                <li v-for="(subCategory, index) in category.subCategories" :key="`${i}/${index}`">
                    <router-link
                    @click.native="showProductsMenu = false;$emit('close')"
                    class="submenu-item"
                    :to="{path: `/shop/products/${category.urlName}/${subCategory.urlName}`}"
                    > {{subCategory.showName}}
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            nameSearcher: ''
        }
    },
    props: {
        categories: Array,
        search: Function
    },
    created(){
        this.$nextTick(() => {
            this.$refs.search.focus()
        })
    }
}
</script>

<style lang="scss" scoped>
.menu-categories{
    list-style: none;
}
.submenu-item {
    font-size: 12px;
    color: black;
    margin-left: 20px;
}
li a:hover {
  color: #32CD32;
}
.menu-item {
    font-size: medium
}

/* The sidebar menu */
.sidebar {
  height: 100%; /* 100% Full-height */
  width: 60%; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Stay on top */
  top: 0;
  left: 0;
  background-color: white; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}

/* Position and style the close button (top right corner) */
.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  color:black
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidebar {padding-top: 15px;}
  .sidebar a {font-size: 18px;}
}
</style>