import axios from 'axios'
import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store/index'

/*------ PageComponents -----*/
import Home from '../views/Home.vue'

/*-----Maintenance-----*/

const Maintenance = () => import('@/views/Maintenance.vue')

/*-----Shop-----*/

const ShopHome = () => import('@/views/shop/ShopHome.vue')
const ProductPage = () => import('@/views/shop/ProductPage.vue')
const ProductSearchPage = () => import('@/views/shop/ProductSearchPage.vue')

/*-----User-----*/

const SignIn = () => import('@/views/user/SignIn.vue')

/*-----Admin-----*/

const AdminHome = () => import('@/views/AdminHome.vue')
const Dashboard = () => import('@/views/admin/Dashboard.vue')
const Products = () => import('@/views/admin/Products.vue')
const Orders = () => import('@/views/admin/Orders.vue')
const ECommerce = () => import('@/views/admin/ECommerce.vue')
const Categories = () => import('@/views/admin/Categories.vue')
const Providers = () => import('@/views/admin/Providers.vue')
const Cards = () => import('@/views/admin/Cards.vue')
const CashRegister = () => import('@/views/admin/CashRegister.vue')

/*-----Localshop-----*/

const LocalShopHome = () => import('@/views/LocalShopHome.vue')
const MainMenu = () => import('@/views/localshop/MainMenu.vue')
const BillGenerator = () => import('@/views/localshop/BillGenerator.vue')
const LocalShopOrders = () => import('@/views/localshop/LocalShopOrders.vue')
const TechnicalService = () => import('@/views/localshop/TechnicalService.vue')

/*-------- Route Guards ----------*/

const shopGuard = async (to, from, next) => {
  const resp = await axios.get('/is-shop-active')
  if(!resp.data)
    return next('/maintenance')
  else next()
}

const adminGuard = async (to, from, next) => {
  const token = localStorage.getItem('token')
  if(!token) return next('/user/signin')
  if(!store.state.role){
    const expiresAt = localStorage.getItem('expiresAt')
    await store.dispatch('userLogIn', {token, expiresAt, isInitialAuth: false})
  } 
  if(store.state.role == 'admin') next()
  else next(store.state.role == 'operator' ? '/localshop/menu' : '/shop')
}

const localshopGuard = (to, from, next) => { //TODO: Arreglar estos guards
  if(!store.state.token) return next('/user/signin')
  if(store.state.role == 'operator' || store.state.role == 'admin')
    next()
  else
    next('/shop')
}

/*--------------------------------*/

Vue.use(VueRouter)

const routes = [
  {
    path: '/shop',
    component: Home,
    children: [
      {
        path: '/',
        component: ShopHome,
        meta: {title: 'ITCentro - Shop'},
        beforeEnter: shopGuard
      },
      {
        path: '/shop/product/:id',
        component: ProductPage,
        meta: {title: 'ITCentro - Shop'},
        beforeEnter: shopGuard
      },
      {
        path: '/shop/products/:categoryUrlName/:subCategoryUrlName',
        component: ProductSearchPage,
        meta: {title: 'ITCentro - Shop'},
        beforeEnter: shopGuard
      },
      {
        path: '/shop/products/search',
        component: ProductSearchPage,
        meta: {title: 'ITCentro - Shop'},
        beforeEnter: shopGuard
      },
    ],
  },
  {
    path: '/maintenance',
    component: Maintenance,
    meta: {title: 'ITCentro - Mantenimiento'}
  },
  {
    path: '/user',
    component: Home,
    children: [
      {
        path: '/user/signin',
        component: SignIn,
        meta: {title: 'ITCentro - Iniciar sesión'}
      },
      {
        path: '/user/signup',
        component: ProductSearchPage,
        meta: {title: 'ITCentro - Registrarse'}
      },
      //TODO: Reset pass
    ]
  },
  {
    path: '/admin',
    component: AdminHome,
    redirect: '/admin/dashboard',
    beforeEnter: adminGuard,
    children: [
      {
        path: '/admin/dashboard',
        component: Dashboard,
        meta: {title: 'ITCentro - Dashboard'}
      },
      {
        path: '/admin/products',
        component: Products,
        meta: {title: 'ITCentro - Productos'}
      },
      {
        path: '/admin/orders',
        component: Orders,
        meta: {title: 'ITCentro - Òrdenes'}
      },
      {
        path: '/admin/categories',
        component: Categories,
        meta: {title: 'ITCentro - Categorìas'}
      },
      {
        path: '/admin/providers',
        component: Providers,
        meta: {title: 'ITCentro - Proveedores'}
      },
      {
        path: '/admin/cards',
        component: Cards,
        meta: {title: 'ITCentro - Tarjetas'}
      },
      {
        path: '/admin/e-commerce',
        component: ECommerce,
        meta: {title: 'ITCentro - Ecommerce'}
      },
      {
        path: '/admin/cash-registers',
        component: CashRegister,
        meta: {title: 'ITCentro - Cajas diarias'}
      },
    ]
  },
  {
    path: '/localshop',
    component: LocalShopHome,
  /*   beforeEnter: localshopGuard, */
    children: [
      {
        path: '/localshop/menu',
        component: MainMenu,
        meta: {title: 'ITCentro - Menú'}
      },
      {
        path: '/localshop/bill-generator',
        component: BillGenerator,
        meta: {title: 'ITCentro - TPV'}
      },
      {
        path: '/localshop/orders',
        component: LocalShopOrders,
        meta: {title: 'ITCentro - Òrdenes'}
      },
      {
        path: '/localshop/technical-service',
        component: TechnicalService,
        meta: {title: 'ITCentro - Servicio Técnico'}
      },
    ],
  },
  {
    path: '/',
    redirect: '/shop'
  },
  {
    path: '/admin*',
    redirect: '/admin/dashboard'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
