import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import AOS from 'aos'
import 'aos/dist/aos.css'

import VueSocketIO from 'vue-socket.io'

import Toasted from 'vue-toasted';

Vue.use(Toasted, {duration: 4000})

Vue.use(new VueSocketIO({
/*   debug: true, */
  connection: 'https://api.itcentroweb.com.ar'
}))

/*--------- AXIOS CONFIG -------*/

import axios from 'axios'

axios.interceptors.request.use(config => {
  config.headers["x-access-token"] = localStorage.getItem('token') || null
  return config
})
axios.defaults.baseURL = 'https://api.itcentroweb.com.ar'

/*------- Changing page title -----------*/

const DEFAULT_TITLE = 'ITCentro'

router.afterEach((to, from) => {
  Vue.nextTick(() => {
      document.title = to.meta.title || DEFAULT_TITLE;
  });
});


/*-------------------------------------*/

new Vue({
  created(){
    AOS.init()
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
